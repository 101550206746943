<template>
  <div class="text-right head-detail--answer-component" v-if="!!resolution_date">
    <div class="line"></div>
    <div class="text">
      <span >Plazo de <br> respuesta</span>
      <span class="text-day">{{resolution_date}}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['resolution_date']
}
</script>
<style lang="scss" scoped>
  .head-detail--answer-component {
    display: grid;
    grid-template-columns: auto max-content;
    margin-top: -39px;
    .line {
      border-bottom: 1px solid #E48E3F;
    }
    .text {
      color: white;
      background: #E48E3F;
      width: min-content;
      border-radius: 30px 30px 30px 0px;
      padding: 5px;
      margin: 0;
      font-size: 10px;
      display: grid;
      grid-template-columns: auto min-content;
      align-items: center;
    }
    .text-day {
      font-size: 16px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 10px;
    }
  }
</style>