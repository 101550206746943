module.exports = {
  ticketDetail: {
    current_status: {
      client_name: '',
      sub_status_name: ''
    },
    created_at: '',
    carrier: {
      code: ''
    },
    delivery: {
      destination: {
        full_address: ''
      },
      tracking_number: '',
      imported_id: '',
      customer: {
        full_name: ''
      },
      manifest: ''
    }
  }
}