<template>
  <b-modal id="idModalUploadAttachments"
    size="lg"
    title="Subir Archivo" modal-class="modal-idModalUpdateDocuments"
    centered
    hide-footer>
    <upload-attachments-ticket :attachmentTypes="attachmentTypes" :ticketId="ticketId" :masterData="masterData" @updateTicket="updateTicket"></upload-attachments-ticket>

  </b-modal>
</template>
<script>
import uploadAttachmentsTicket from '@/views/modules/tickets/components/updateDocuments/uploadAttachmentsTicket.vue'
export default {
  props: ['attachmentTypes', 'ticketId', 'masterData'],
  components: { uploadAttachmentsTicket },
  methods: {
    updateTicket() {
      this.$emit('updateTicket', true)
    }
  }
}
</script>