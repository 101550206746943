<template>
  <b-row class="p-2 bg-white">
    <b-col cols="12" class="mb-1">
      <b-skeleton-img no-aspect width="35%" height="30px" class="mb-1"></b-skeleton-img>
    </b-col>
    <b-col cols="12">
      <b-skeleton width="25%"/>
    </b-col>
    <b-col cols="12" class="mb-2">
      <b-skeleton-img no-aspect height="90px"></b-skeleton-img>
    </b-col>
    <b-col>
      <b-skeleton-img></b-skeleton-img>
    </b-col>
    <b-col>
      <b-skeleton-img no-aspect class="mb-1" height="15%"></b-skeleton-img>
      <br/>
      <b-skeleton-img no-aspect class="mb-1" height="15%"></b-skeleton-img>
      <br/>
      <b-skeleton-img no-aspect class="mb-1" height="15%"></b-skeleton-img>
      <br/>
      <b-skeleton-img no-aspect class="mb-1" height="15%"></b-skeleton-img>
    </b-col>
 </b-row>
</template>

<script>
export default {
  name: 'SkeletonTicketLoader'
}
</script>
