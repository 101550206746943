<template>
  <b-card>
    <div class="head-detail-resume">
      <!-- <span>
        <feather-icon icon="MailIcon"/> {{ticket.delivery.deadline_date ? formatDate(ticket.delivery.deadline_date) : 'Sin información' }}
      </span> -->
      <span v-b-tooltip.hover title="OT y Referencia">
        <feather-icon icon="SendIcon"/> OT:
        {{ ticket.delivery.tracking_number }} / Ref:
        {{ ticket.delivery.imported_id }}
      </span>
      <span v-b-tooltip.hover title="Fecha limite">
        <feather-icon icon="CalendarIcon"/> {{ deadLineText }}
      </span>
      <span v-b-tooltip.hover title="Logo Courier">
        <img v-if="ticket && ticket.carrier && ticket.carrier.code" :src="getImage(ticket.carrier.code)" class="image" :alt="ticket.carrier.code">
      </span>
      <!-- <span
        ><feather-icon icon="MapPinIcon"/>{{
          ticket.delivery.destination.full_address
        }}
      </span>
      <span>
        <feather-icon icon="UserIcon"/>{{ ticket.delivery && ticket.delivery.customer && ticket.delivery.customer.full_name ? ticket.delivery.customer.full_name: '' }}
      </span> -->
      <span v-b-tooltip.hover title="Estado de envío">
        <feather-icon icon="TruckIcon"/>{{ currentDeliveryText }}
      </span>
      <span v-b-tooltip.hover title="Fecha de estado de envío">
        <feather-icon icon="CalendarIcon"/>{{ currentDeliveryDate }}
      </span>
      <span v-b-tooltip.hover title="Tipo de contrato">
        <feather-icon icon="FileIcon"/> {{ textContractType }}
      </span>
      <span v-b-tooltip.hover title="Dias de transito">
        <feather-icon icon="CalendarIcon"/> {{ ticket.delivery.transit_days }}
      </span>
      <span v-b-tooltip.hover title="Dirección de destino">
        <feather-icon icon="MapPinIcon"/>{{
          ticket.delivery.destination.full_address_text
        }}
      </span>
    </div>
  </b-card>
</template>
<script>
export default {
  props: ['ticket'],
  data() {
    return {
      contractTypeOptions: [
        { id: 'PAS', text: 'Pasarela' },
        { id: 'DIS', text: 'Distribución' },
        { id: 'pasarela-pro', text: 'Pasarela-Pro' },
        {id: 'INV', text: 'Logística inversa (Devolución)'}
      ]
    }
  },
  computed: {
    deadLineText() {
      return this.ticket?.delivery?.deadline_date ? this.ticket.delivery.deadline_date : 'Sin información'
    },
    currentDeliveryDate() {
      return this.ticket?.current_delivery_status?.date ? this.ticket.current_delivery_status.date : 'Sin información'
    },
    currentDeliveryText() {
      return this.ticket?.current_delivery_status?.name ? this.ticket.current_delivery_status.name : ''
    },
    textContractType() {
      return this.ticket?.contract_type ? this.contractTypeOptions.filter(el => el.id === this.ticket.contract_type)[0].text : 'Sin información'
    }
  },
  methods: {
    getImage(code) {
      return `https://storage.googleapis.com/enviame-misc/carriers/logos/color/${code.toLowerCase()}.png`
    },
    formatDate(date) {
      return this.$options.filters.dbDateToFormat(date, 'y-LL-dd T')
    }
  }
}
</script>
<style lang="scss" scoped>
.head-detail-resume {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-items: center;
  align-items: center;
  gap: 20px;
  color: #044389;
  .feather {
    margin-right: 5px;
  }
  .image {
    width: 100px;
    max-height: 60px;
    object-fit: cover;
  }
}
.head-detail-resume > span {
  text-align: center;
}
</style>