<template>
  <section>
    <div v-if="message.type === 'message'" class="body--message-container" :class="isRight? 'body--detail--message-right' : ''">
      <div v-if="!isRight">
        <img v-if="carrierCode !== ''" :src="getImage(carrierCode)" class="image drop--down-photo" :alt="carrierCode">
        <feather-icon v-else class="drop--down-photo" icon="UserIcon"/>
      </div>
      <b-card no-body class="body--detail--message">
        <div class="body--detail--card-body">
          <div class="body--detail--card-head">
            <strong v-show="!!message.user_name">{{message.user_name}}</strong>
            <b-badge class="body--detail--badge" v-show="message.hidden" variant="danger" title="Mensaje oculto">Mensaje oculto</b-badge>
            <b-badge class="body--detail--badge" v-show="message.title" :variant="message.title_color === 'plain' ? 'secondary' : 'warning'" title="Nuevo asunto">Nuevo asunto: {{message.title}}</b-badge>
            <b-badge class="body--detail--badge" v-show="message.new_type_name" :variant="message.new_type_color === 'plain' ? 'secondary' : 'warning'" title="Nuevo asunto"> {{message.new_type_name}}</b-badge>
          </div>
          <p class="m-0 body--detail--message-text mt-1 mb-1">{{message.message}}</p>
          <div class="body--footer--container">
            <div>
              <i v-if="message.resolution_date && message.resolution_date_log && message.resolution_date_log.resolutionDays" :id="`tooltip-target-${message.index}`" class="fa fa-info-circle text-info body--detail--badge"></i>
            </div>
            <div>
              <b-badge v-if="message.resolution_date" :class="`badge badge-warning body--detail--badge`">Fecha máxima de resolución: {{ message.resolution_date }}</b-badge>
              <b-tooltip v-if="message.resolution_date && message.resolution_date_log && message.resolution_date_log.resolutionDays" :target="`tooltip-target-${message.index}`" triggers="hover">
                <b>Num. días de resolución:</b> {{message.resolution_date_log.resolutionDays}}<br>
                <b>Descripción de problema:</b> {{message.resolution_date_log.note}}<br>
                <b>Campo inicio de conteo:</b> {{message.resolution_date_log.initDateField}}<br>
                <b>Fecha de inicio de conteo:</b> {{message.resolution_date_log.initDateValue}}
              </b-tooltip>
            </div>
            <div>
              <b-button @click="openModalDelete(message)" v-if="message.deletable" variant="link" class="p-0 text-danger">{{message.deletable.label}}</b-button>
            </div>
          </div>
        </div>
      </b-card>
      <div v-if="isRight">
        <!-- <feather-icon class="drop--down-photo" icon="UserIcon"/> -->
        <img class="drop--down-photo" :src="require(`@/assets/images/logo/enviame_logo.svg`)" alt="image">
      </div>
      <span class="body--detail--create-at">{{message.created_at_formatted}}</span>
    </div>
    <div v-if="message.type === 'tag'"  class="body--tag-container">
      <div class="body--tag-message">
        <p class="body--tag-message-text">{{message.tracking_data.name}}</p>
        <i :id="isTheModal ? `tooltip-target-tag-modal-${index}` : `tooltip-target-tag-${index}`" class="body--detail--badge fa fa-info-circle text-primary"></i>
        <b-tooltip :target="isTheModal ? `tooltip-target-tag-modal-${index}` : `tooltip-target-tag-${index}`" triggers="hover" placement="bottom">
          <b>CÓDIGO: {{message.tracking_data.code ? message.tracking_data.code : '--'}}</b>
          <br><br>
          <b>ESTADO COURIER:</b> {{message.tracking_data.carrier_status ? message.tracking_data.carrier_status : '--'}} <br>
          <b>ORIGEN:</b> {{message.tracking_data.carrier_origin ? message.tracking_data.carrier_origin : '--'}} <br>
          <b>FECHA ESTADO: {{message.tracking_data.carrier_status_date ? message.tracking_data.carrier_status_date : '--'}} </b> <br>
          <br>
          <b>ESTADO ENVÍAME: {{message.tracking_data.enviame_status ? message.tracking_data.enviame_status : '--'}}</b> <br>
          <b>COMENTARIO:{{message.tracking_data.enviame_comment ? message.tracking_data.enviame_comment : '--'}} </b> <br>
          <b>ORIGEN:{{message.tracking_data.enviame_origin ? message.tracking_data.enviame_origin : '--'}} </b> <br>
          <b>FECHA TRACKING: {{message.tracking_data.enviame_status_date ? message.tracking_data.enviame_status_date : '--'}} </b> 
        </b-tooltip>
      </div>
      <span class="body--detail--create-at">{{message.created_at_formatted}}</span>
    </div>
  </section>
</template>
<script>
import {
  BBadge
} from 'bootstrap-vue'
import BaseServices from '@/store/services/index'
export default {
  props: ['message', 'ticket_id', 'index', 'isTheModal', 'carrierCode'],
  components: {BBadge},
  computed: {
    isRight() {
      return this.message.align === 'right'
    }
  },
  data() {
    return {
      baseService: new BaseServices(this)
    }
  },
  methods: {
    getImage(code) {
      return `https://storage.googleapis.com/enviame-misc/carriers/logos/color/${code.toLowerCase()}.png`
    }
  }
}
</script>
<style lang="scss" scoped>
  .body--detail--message {
    background: #E6E6E6;
    width: 100%;
    border-radius: 60px 60px 60px 0;
    margin: 0;

    .body--detail--card-body {
      padding: 20px;
      display: grid;
    }

    .body--detail--card-head {
      display: flex;
      gap: 10px;
    }

    &-text {
      white-space: preserve;
    }
  }

  .drop--down-photo {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 3.5rem;
    background-color: #E8EDF1;
    padding: .5rem;
  }
  .image.drop--down-photo {
    background-color: transparent;
    padding: 0;
    object-fit: contain;
  }
  .body--message-container {
    display: grid;
    grid-template-columns: 3.5rem 1fr;
    gap: 5px;
    align-items: end;
  }
  .body--detail--create-at {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.21633554995059967px;
    padding: 5px 0 0 0;
    text-align: left;
    grid-column: 2 / 2;
  }
  .body--message-container.body--detail--message-right {
    grid-template-columns: 1fr 3.5rem;
    justify-items: flex-end;
    align-items: end;
  }

  .body--message-container .body--footer--container {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 10px;
    justify-items: self-end;

    button {
      width: fit-content;
    }
  }
  .body--detail--message-right {
    .body--detail--message {
      float: right;
      background: #DDEFFF;
      border-radius: 60px 60px 0px 60px;
    }
    .body--detail--create-at {
      grid-column: 1 / 2;
    }
  }
  .body--detail--badge {
    width: min-content;
    align-self: center;
  }
  .body--tag-container {
    display: flex;
    flex-flow: column;
    align-items: center;

    .body--tag-message {
      width: 332px;
      height: 75px;
      gap: 0;
      border-radius: 60px 0 60px 0;
      background: #FDF3D0;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      &-text {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
        text-align: center;
        margin: 0;
        color: #6E6B7B;
      }
    }
  }
</style>