<template>
  <div>
    <p>Selecciona el tipo de archivo que quieres subir</p>
    <b-form-select v-model="attachmentSelectCode" :options="attachmentTypesOptions" @change="setFields"></b-form-select>
    <b-card v-if="fields.length > 0" class="mt-1">
      <form-render  :form.sync="form" :fields="fields" @send="onAccept" :key="keyFormRender" containerButtonsClass="vx-col w-full" ref="formRenderNewTicket">
        <template #buttonsGroupSlot>
          <b-button variant="warning" type="submit" :disabled="loading">{{adminVersion ? 'Guardar' : 'Confirmar'}}</b-button>
        </template>
      </form-render>
    </b-card>
  </div>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  props: ['attachmentTypes', 'ticketId', 'masterData', 'ticket'],
  data() {
    return {
      baseService: new BaseServices(this),
      keyFormRender: 0,
      fields: [],
      form: {},
      loading: false,
      attachmentSelectCode: ''
    }
  },
  computed: {
    attachmentTypesArray() {
      return this.attachmentTypes ? this.attachmentTypes : this.masterData.attachment_types
    },
    idTicket() {
      return this.ticketId ? this.ticketId : this.ticket.delivery.id
    },
    attachmentSelect() {
      return this.attachmentTypesArray.find(el => el.code === this.attachmentSelectCode)
    },
    attachmentTypesOptions() {
      return this.attachmentTypesArray ? this.attachmentTypesArray.map((element) => ({
        ...element,
        value: element.code,
        text: element.name
      })) : []
    },
    dataUser() {
      return this.$session.get('cas_user')
    },
    adminVersion() {
      return this.dataUser.role === 'admin'
    },
    country() {
      return this.ticket?.carrier?.country ? this.ticket.carrier.country : 'CL'
    }
  },
  methods: {
    onAccept(data) {
      let form = {}
      let file = null
      if (data) {
        this.fields.forEach(el => {
          if (el.name !== 'file' && el.name !== 'buttonsGroupSlot') {
            form[el.name] = data[el.name]
          } else if (el.name === 'file') {
            file = data[el.name]
            form = {...form,
              'attachment_type_code': this.attachmentSelect.code.toUpperCase(),
              'confirm_summary': true,
              'send_message': 1,
              'delivery_id': this.idTicket
            }
          }
        })
      }
      this.updateFiles(form, file)
    },
    resetValues() {
      this.fields = []
      this.form = {}
    },
    setFields() {
      this.resetValues()
      if (this.adminVersion) {
        this.fields.push({ fieldType: 'FieldFile', name: 'file', label: 'Subir Archivo', containerClass: 'vx-col w-full container-info', multiple: !!this.attachmentSelect.is_unique, validation: 'required', adminVersion: true})
      } else {
        this.fields.push({ fieldType: 'FieldFile', name: 'file', label: 'Adjuntar Archivo', containerClass: 'vx-col w-full container-info', multiple: !!this.attachmentSelect.is_unique, validation: 'required'})
      }
      const countrySelect = this.masterData.countries.find(el => el.code === this.country)
      if (!!this.attachmentSelect.extra_fields) {
        this.attachmentSelect.extra_fields.map(field => {
          switch (field.type) {
          case 'string':
            this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'vx-col w-full container-info', type: 'text'})
            break
          case 'number':
            if (field.name === 'price') {
              this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'vx-col w-full container-info', type: 'number', append: {text: countrySelect && countrySelect.currency ? countrySelect.currency : 'Peso Chileno'}})
            } else {
              this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'vx-col w-full container-info', type: 'number'})
            }
            break
          case 'phone':
            if (countrySelect && countrySelect.mask_number) field.mask = countrySelect.mask_number
            if (countrySelect && countrySelect.phone_code) field.indicator = countrySelect.phone_code
            this.fields.push({...field, fieldType: 'FieldInput', containerClass: 'vx-col w-full container-info', type: 'tel', clearable: true})
            break
          case 'array': {
            const optionsArray = this.setOptionsArray(this.masterData[field.name])
            if (field.disabled === true) {
              field.validation = ''
              this.fields.push({...field, fieldType: 'FieldSelect', containerClass: 'vx-col w-full container-info', options: optionsArray})
            } else {
              this.fields.push({...field, fieldType: 'FieldSelect', containerClass: 'vx-col w-full container-info', options: optionsArray})
            }
            break
          }
          case 'file':
            this.fields.push({...field, fieldType: 'FieldFile', containerClass: 'vx-col w-full container-info', multiple: !field.unique})
            break
          case 'file_picker': {
            const optionFilePicker = this.setFileOptions(field)
            this.fields.push({...field, fieldType: 'FieldSelect', name: `${field.name}Select`, containerClass: 'vx-col w-full container-info', options: optionFilePicker })
            this.fields.push({...field, fieldType: 'FieldFile', name:`${field.name}File`, label: '', containerClass: 'vx-col w-full container-info', multiple: !field.unique})
            break }
          case 'textarea':
            this.fields.push({...field, fieldType: 'FieldTextarea',  containerClass: 'vx-col w-full container-info'})
            break
          default:
            this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'vx-col w-full container-info', type: 'text'})
            break
          }
        })
      }
      this.fields.push({name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12 p-0 d-flex justify-content-end'})
      this.keyFormRender++
    },
    setFileOptions (val) {
      return val.attachment_types.map(element => ({id: element.type, text: element.name }))
    },
    setPlace() {
      const index =  this.masterData.place.findIndex(el => el.id === this.ticket.delivery.destination.place_id)
      return this.masterData.place[index]
    },
    setOptionsArray (optionsArray) {
      return optionsArray ? optionsArray.map(element => ({
        ...element,
        id: element.id ?? element.code,
        text: element.text ?? element.name
      })) : []
    },
    updateFiles (data, file) {
      this.loading = true
      this.baseService.callSpecialUploadFile('updateFiles', file, { id: this.idTicket },  this.$session.get('cas_user'), data, 'files')
        .then(response => {
          if (response.code === 'success') {
            this.$emit('updateTicket', true)
            this.$bvModal.hide('idModalUploadAttachments')
            this.$success('Archivos agregados correctamente')
            this.form = {}
          }
        })
        .catch(err => {
          const text = 'Ocurrió un problema al subir el archivo'
          let textMessage = ''
          if (Array.isArray(err)) {
            textMessage += '<ul style="list-style-type: disc;">'
            err.forEach(element => {
              textMessage += `<li>${element.message}</li>`
            })
            textMessage += '</ul>'
          }
          this.$alert(textMessage ? textMessage : text)
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>