<template>
  <form-render v-if="viewForm" :form.sync="form" :fields="fields" @send="onAccept" :key="keyFormRender"
    containerButtonsClass="col-12 d-flex justify-content-end">
    <template #buttonsGroupSlot>
      <b-button variant="warning" type="submit" :disabled="loading">{{nameButton}}</b-button>
    </template>
    <template #warehouseSlot>
      <label for="">Bodega *</label>
      <b-form-select placeholder="Selecciona una bodega" v-model="form.warehouse" :options="optionWarehouse"
        @change="changeWarehouse">
        <template #first>
          <b-form-select-option value="empty" >Elige una dirección diferente</b-form-select-option>
        </template>
      </b-form-select>
    </template>
  </form-render>
</template>
<script>
export default {
  props: ['extraField', 'masterData', 'ticket', 'delivery', 'options'],
  data() {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      viewForm: false
    }
  },
  watch: {
    'extraField': {
      handler(value) {
        this.setFormExtraFields(value)
      }
    }
  },
  computed: {
    loading() {
      return !!this.options?.loading
    },
    optionWarehouse() {
      return this.masterData.warehouse ? this.masterData.warehouse.map(element => ({
        ...element,
        value: element.id ?? element.identifier ?? element.code,
        text: element.text ?? element.name
      })) : []
    },
    warehouseSelect() {
      return this.optionWarehouse.find(el => el.value === this.form.warehouse)
    },
    nameButton() {
      return this.options?.nameButton ? this.options.nameButton : 'Confirmar'
    }, 
    country() {
      const countryTicket = this.ticket?.carrier?.country ? this.ticket.carrier.country : null
      const countryTicketDefault = this.masterData?.defaults?.country !== '' ? this.masterData?.defaults?.country : null
      return countryTicket || countryTicketDefault || 'CL'
    },
    fullAddress() {
      const ticketAddress = this.ticket?.delivery?.destination?.full_address ?? null
      const deliveryAddress = this.delivery?.address?.full_address ?? null
      const addressDefault = this.masterData?.defaults?.full_address !== '' ? this.masterData?.defaults?.full_address : null
      return  ticketAddress || deliveryAddress || addressDefault || ''
    },
    placeId() {
      const ticketId = this.ticket ? this.ticket?.delivery?.destination?.place_id : null
      const deliveryId = this.delivery?.destination?.place_id ?? this.delivery?.place_id ?? null
      const placeIdDefault = this.masterData?.defaults?.place_id !== 0 ? this.masterData?.defaults?.place_id : null
      return  ticketId || deliveryId || placeIdDefault || null
    },
    warehouseTicket() {
      const warehouseIdTicket = this.ticket?.delivery?.origin?.warehouse_id ? this.ticket?.delivery?.origin?.warehouse_id : null
      const warehouseIdDefault = this.masterData?.defaults?.warehouse.identifier !== 0 ? this.masterData?.defaults?.warehouse.identifier : null
      return warehouseIdTicket || warehouseIdDefault || null
    },
    phoneDelivery() {
      return this.delivery?.phone ? this.delivery.phone : null
    }
  },
  methods: {
    changeDisableStatus(name, status = false) {
      const index =  this.fields.findIndex(el => el.name === name)
      this.fields[index].disabled =  status
    },
    changeWarehouse(value) {
      if (value !== 'empty') {
        const warehouse = this.warehouseSelect
        this.form.place = this.setPlace(warehouse?.address?.place_id)
        this.form.full_address = warehouse?.address?.full_address
        this.changeDisableStatus('place', true)
        this.changeDisableStatus('full_address', true)
      } else {
        this.form.place = {}
        this.form.full_address = ''
        this.changeDisableStatus('place', false)
        this.changeDisableStatus('full_address', false)
      }
      this.keyFormRender++
    },
    setFormExtraFields(value) {

      this.fields = []
      this.viewForm = false
      if (!!value?.extra_fields && value.extra_fields.length > 0) {
        this.viewForm = true
        const countrySelect = this.masterData.countries.find(el => el.code === this.country)
        value.extra_fields.map(field => {
          if (field.max_length) {
            field.max = field.maxLength = field.max_length
          }
          switch (field.type) {
          case 'string':
            this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'col-sm-12 container-info',
              type: 'text'})
            break
          case 'phone':
            if (countrySelect && countrySelect.mask_number) field.mask = countrySelect.mask_number
            if (countrySelect && countrySelect.phone_code) field.indicator = countrySelect.phone_code
            this.fields.push({...field, fieldType: 'FieldInput', containerClass: 'col-sm-12 container-info', 
              type: 'tel', clearable: true})
            break
          case 'place':
          case 'array': {
            const optionsArray = this.setOptionsArray(this.masterData[field.name])
            if (field.disabled === true) {
              field.validation = ''
              this.fields.push({...field, fieldType: 'FieldSelect', containerClass: 'col-sm-12 container-info',
                options: optionsArray})
            } else {
              this.fields.push({...field, fieldType: 'FieldSelect', containerClass: 'col-sm-12 container-info',
                options: optionsArray})
            }
            break
          }
          case 'warehouse': {
            this.fields.push({name:'warehouseSlot', useSlot: true, containerClass: 'col-sm-12 container-info'})
            break
          }
          case 'file':
            this.fields.push({...field, fieldType: 'FieldFile', containerClass: 'col-sm-12 container-info',
              multiple: !field.unique})
            break
          case 'file_picker': {
            const optionFilePicker = this.setFileOptions(field)
            this.fields.push({...field, fieldType: 'FieldSelect', name: `${field.name}Select`,
              containerClass: 'col-sm-12 container-info', options: optionFilePicker })
            this.fields.push({...field, fieldType: 'FieldFile', name:`${field.name}File`, label: '',
              containerClass: 'col-sm-12 container-info', multiple: !field.unique})
            break }
          case 'textarea':
            this.fields.push({...field, fieldType: 'FieldTextarea',  containerClass: 'col-sm-12 container-info'})
            break
          case 'number':
            if (field.name === 'price' || field.name === 'value_to_claim') {
              this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'col-sm-12 container-info', type: 'number', append: {text:  countrySelect && countrySelect.currency ? countrySelect.currency : 'Peso Chileno'}})
            } else {
              this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'col-sm-12 container-info', type: 'number'})
            }
            break
          default:
            this.fields.push({...field, fieldType: 'FieldInput',  containerClass: 'col-sm-12 container-info',
              type: 'text'})
            break
          }
        })
      } else {
        this.viewForm = true
        this.fields.push({fieldType: 'FieldTextarea', type: 'textarea', name: 'description', label: 'Descripción',
          containerClass: 'col-sm-12 container-info', validation: 'optional', disabled: false, placeholder: 'Descripción'})
      }
      this.fields.push({name: 'buttonsGroupSlot', useSlot:true, containerClass: 'col-12 p-0 d-flex justify-content-end'})
      this.setArrangementsField(value)
    },
    setArrangementsField(value) {
      this.fields.map((field) => {
        if (value.code === 'RETRY') {
          if (field.name === 'full_address') {
            this.form.full_address = this.fullAddress
          }
          if (field.name === 'place') {
            this.form.place = this.setPlace()
          }
        } else if (value.code === 'CHANGE_ADDRESS') {
          if (field.name === 'place') {
            this.form.place = this.setPlace()
            this.form.full_address = this.fullAddress
            this.form.phone = this.phoneDelivery
          }
        } else if (value.code === 'RETURN' && (field.name === 'warehouse' || field.name === 'warehouseSlot')) {
          if ((field.name === 'warehouse' || field.name === 'warehouseSlot')) {
            this.form.warehouse = this.setWarehouse()
          }
          return field
        } else if (value.code === 'RETURN' && (field.name === 'place' || field.name === 'full_address')) {
          field.disabled = true
          return field
        }
      })
      this.keyFormRender++
    },
    setFileOptions (val) {
      return val.attachment_types.map(element => ({id: element.type, text: element.name }))
    },
    setOptionsArray (optionsArray) {
      return optionsArray ? optionsArray.map(element => ({
        ...element,
        id: element.id ?? element.identifier ?? element.code,
        value: element.id ?? element.identifier ?? element.code,
        text: element.text ?? element.name
      })) : []
    },
    setPlace(idPlace = null) {
      const setIdPlace = idPlace ? idPlace : this.placeId
      const index =  this.masterData.place.findIndex(el => el.id === setIdPlace)
      return this.masterData.place[index]
    },
    setWarehouse(warehouseData = null) {
      let warehouse = {}
      if (!warehouseData && !!this.warehouseTicket) {
        const index =  this.masterData.warehouse.findIndex(el => el.identifier === this.warehouseTicket)
        warehouse = this.masterData.warehouse[index]
      }
      if (!!warehouseData || !!warehouse) {
        warehouse.id = warehouse?.identifier ?? warehouseData?.identifier
        this.form.place = this.setPlace(warehouse?.address?.place_id ?? warehouseData?.address?.place_id)
        this.form.full_address = warehouse?.address?.full_address ?? warehouseData?.address?.full_address
      }
      return warehouse.id
    },
    createMessage (extraFields, labelExtraFields) {
      let message = ''
      const exceptions = ['place', 'invoiceFile', 'invoiceSelect', 'warehouse', 'product', 'warehouseSlot']
      for (const key in extraFields) {
        if (Object.hasOwnProperty.call(extraFields, key)) {
          const objectLabel = labelExtraFields.filter(el => el.name === key)[0]
          if (objectLabel) {
            if (!exceptions.includes(key) && !!objectLabel['label'] && extraFields[key]) {
              message += `${objectLabel['label']}: ${extraFields[key]} \n`
            } else if (key === 'invoiceSelect') {
              message += `${objectLabel['label']}: ${extraFields[key].text} \n`
            } else if (key === 'place') {
              message += `${extraFields[key].value.place_1.label}: ${extraFields[key].value.place_1.value} \n${extraFields[key].value.place_2.label}: ${extraFields[key].value.place_2.value} \n`
            } 
          }
        }
      }
      if (!!this.form.warehouse) {
        message += `Bodega: ${this.warehouseSelect.name} \n`
      }
      if (message === '') message = this.message
      return message
    },
    setExtraFieldsForm(selectType, data) {
      const formReturn = {
        extra_fields: {},
        fileToSend: []
      }
      let objectLabel = {}
      if (selectType.extra_fields && selectType.extra_fields.length > 0) {
        selectType.extra_fields.forEach(el => {
          switch (el.type) {
            
          case 'file_picker':
            if (data && data[`${el.name}File`]) {
              formReturn.fileToSend = [...formReturn.fileToSend, {
                'queryParams': {
                  'attachment_type_code': data[`${el.name}Select`].id.toUpperCase(),
                  'confirm_summary': true,
                  'send_message': 1,
                  'price': data[`${el.attachment_extra_fields.price}`],
                  'delivery_id': this.ticket.delivery.id
                },
                'files': data[`${el.name}File`]
              }]
            }
            break
          case 'file':
            if (data[el.name]) {
              formReturn.fileToSend = [...formReturn.fileToSend, {
                'queryParams': {
                  'attachment_type_code': el.name.toUpperCase(),
                  'confirm_summary': true,
                  'send_message': 1,
                  'delivery_id': this.ticket.delivery.id
                },
                'files': data[el.name]
              }]
            }
            break
          case 'place':
            objectLabel = this.objectField(el)
            if (el.disabled === true) {
              formReturn.extra_fields[el.name] = { 
                value: this.ticket.delivery.origin.place_id,
                label: objectLabel['label'] ?? ''
              }
            } else {
              formReturn.extra_fields[el.name] = { 
                value: data[el.name] && data[el.name].id ? data[el.name].value : data[el.name], 
                label: objectLabel['label'] ?? ''
              }
            }
            break
          case 'warehouse':
            formReturn.extra_fields.warehouse = {
              value: this.warehouseSelect,
              label: 'Bodega'
            }
            break
          case 'phone':
            objectLabel = this.objectField(el)
            formReturn.extra_fields.phone = {value: data['phone'].replace(/\s/g, ''), label:  objectLabel['label'] ?? ''}
            break
          default:
            objectLabel = this.objectField(el)
            formReturn.extra_fields[el.name] = { 
              value: data[el.name] && data[el.name].id ? data[el.name].id : data[el.name],
              label: objectLabel?.label ?? ''
            }
            break
          }
        })
      } else {
        formReturn.extra_fields = {
          description: this.form.description
        }
      }
      return formReturn
    },
    objectField(el) {
      return this.fields.filter(element => element.name === el.name)[0]
    },
    onAccept() {
      const dataEmit = {
        extra_fields: {
          form: {...this.form},
          data_organized: this.ticket ? this.setExtraFieldsForm(this.extraField, this.form) : {},
          message: this.createMessage(this.form, this.fields)
        },
        ticket_type_code: this.extraField
      }
      if (!!this.form.warehouse) {
        dataEmit.extra_fields.form.warehouse = this.warehouseSelect
      }
      this.$emit('updateExtraField', dataEmit)
    }
  }
}
</script>