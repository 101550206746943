<template>
  <section v-if="checkMasterTicket">
    <form-render :form.sync="form" :fields="fields" :key="keyFormRender" @send="onAccept">
      <template #buttonsGroupSlot>
        <div class="button--container-claim">
          <b-button variant="outline-primary" title="Editar" @click="changeDisable" v-show="!flagDisabled">Editar</b-button>
          <b-button variant="outline-danger" title="Cancelar" @click="setDataDefault" v-show="flagDisabled" :disabled="!flagDisabled || checkLoading">Cancelar</b-button>
          <b-button variant="warning" title="Guardar" v-show="flagDisabled" :disabled="!flagDisabled || checkLoading" type="submit">Guardar</b-button>
        </div>
      </template>
    </form-render>
  </section>
</template>
<script>
export default {
  props: ['ticket', 'masterData', 'loading', 'formActive'],
  data() {
    return {
      form: {},
      fields: [],
      keyFormRender: 0,
      flagDisabled: true
    }
  },
  mounted() {
    this.setForm()
  },
  computed: {
    checkMasterTicket() {
      return !!this.ticket && !!this.masterData
    },
    checkLoading() {
      return this.loading === 'loading'
    },
    country() {
      return this.ticket?.carrier?.country ? this.ticket.carrier.country : 'CL'
    },
    currencySelect() {
      return this.masterData && this.masterData.countries ? this.masterData.countries.find(el => el.code === this.country).currency : 'Peso Chileno'
    } 
  },
  watch: {
    formActive(value) {
      if (value !== 'compensation-data' && this.flagDisabled) this.disableFields()
    }
  },
  methods: {
    setForm() {
      this.fields = [
        {fieldType: 'FieldInput', name: 'complaint_amount_corrected', label: 'Monto reclamo verificado', containerClass: 'col-12 col-md-6 container-info', type:'number', append: {text: this.currencySelect}, enableStep: true},
        {fieldType: 'FieldInput', name: 'carrier_payment_amount', label: 'Monto aceptación courier', containerClass: 'col-12 col-md-6 container-info', type:'number', append: {text: this.currencySelect}, enableStep: true},
        {fieldType: 'FieldDatepicker', name: 'carrier_payment_date', label: 'Fecha aceptación courier', containerClass: 'col-12 col-md-6 container-info', clearable: true},
        {fieldType: 'FieldInput', name: 'payment_amount_corrected', label: 'Monto pago Enviame', containerClass: 'col-12 col-md-6 container-info', type:'number', append: {text: this.currencySelect}, enableStep: true},
        {fieldType: 'FieldInput', name: 'exceptional_payment_correction', label: 'Monto corrección excepcional', containerClass: 'col-12 col-md-6 container-info', type:'number', append: {text: this.currencySelect}, enableStep: true},
        {fieldType: 'FieldInput', name: 'carrier_payment_number', label: 'Nro. de aceptación', containerClass: 'col-12 col-md-6 container-info'},
        {fieldType: 'FieldInput', name: 'enviame_document_number', label: 'Nro. documento Envíame', containerClass: 'col-12 col-md-6 container-info'},
        {name: 'buttonsGroupSlot', useSlot: true, containerClass: 'col-12'}
      ]
      this.setDataDefault()
    },
    setDataDefault() {
      this.form = {}
      this.fields.map(el => {
        if (this.ticket.compensation_data && el.name !== 'buttonsGroupSlot') {
          this.form[el.name] = this.ticket.compensation_data[el.name] ?? null
        } else {
          this.form[el.name] = null
        }
      })
      this.changeDisable()
    },
    changeDisable() {
      this.$emit('editIn', !this.flagDisabled ? 'compensation-data' : 'cancel')
      this.disableFields()
    },
    disableFields() {
      this.fields.map(el => {
        el.disabled = this.flagDisabled
      })
      this.flagDisabled = !this.flagDisabled
      this.keyFormRender++
    },
    onAccept() {
      this.changeDisable()
      this.$emit('saveDataTicket', {compensation_data: {...this.form}, overwrite_complaint_data: true})
    }
  }
}
</script>