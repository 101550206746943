<template>
  <div class="body--detail--attachment">
      <div class="button-group">
        <b-button v-for="(element, index) in optionsAttachment"
          :key="index"
          :pressed="viewAttachment === element.value"
          variant="outline-primary"
          @click="setArrayAttachment(element.value)">{{element.text}}</b-button>
      </div>
      <div>
        <b-button variant="warning" v-b-modal.idModalUploadAttachments>Subir Adjunto</b-button>
      </div>
    <b-card>
      <table-render :schema="schema" :rows="viewArray" :fixed="true">
        <template #iconSlot="scope">
          <icons :icon="getIcon(scope.rowdata.mimetype)"></icons>
        </template>
        <template #extraFieldsSlot="scope">
          <div v-for="(element, index) in scope.rowdata.extra_fields" :key="index">
            {{element.value}}
          </div>
        </template>
        <template #dateSlot="scope">
          <div>
            {{formatDate(scope.rowdata.date)}}
          </div>
        </template>
        <template #actionsSlot="scope">
          <div>
            <b-button variant="flat-success" v-b-tooltip.hover title="Ver Archivo" class="p-0" @click="openIframe(scope.rowdata)"><feather-icon icon="EyeIcon" /></b-button>
          </div>
        </template>
      </table-render>
    </b-card>
    <modal-detail-attachment-vue :arrayElements="viewArray" :indexFirst.sync="indexFirst"  ref="modalDetailAttachment"></modal-detail-attachment-vue>
    <modal-upload-attachments v-if="ticket && ticket.delivery && ticket.delivery.id" :ticketId="ticket.delivery.id" :attachmentTypes="attachmentTypes" :masterData="masterData" @updateTicket="updateTicket"></modal-upload-attachments>
  </div>
</template>
<script>
import Icons from '@/Icons/Icons.vue'
import ModalDetailAttachmentVue from '@/views/modules/tickets/components/Modals/ModalDetailAttachment.vue'
import ModalUploadAttachments from '@/views/modules/tickets/components/Modals/modalUploadAttachments.vue'

export default {
  components: {Icons, ModalDetailAttachmentVue, ModalUploadAttachments},
  props: ['attachments', 'attachmentTypes', 'ticket', 'masterData'],
  data() {
    return {
      viewAttachment: '',
      schema: [
        {label: '', key: 'iconSlot', useSlot: true},
        {label: 'Archivo', key: 'filename', sortable: true},
        {label: 'Tipo de archivo', key: 'attachment_type_name', sortable: true},
        {label: 'Datos adicionales', key: 'extraFieldsSlot', useSlot: true},
        {label: 'Modo de ingreso', key: 'type_upload', sortable: true},
        {label: 'Fecha de Subida', key: 'dateSlot', useSlot: true},
        {label: 'Acciones', key: 'actionsSlot',  useSlot: true, class: ['text-center thead--light']}
      ],
      indexFirst: 0,
      optionsAttachment: [
        {text: 'Manifiesto', value: 'SUMMARY'},
        {text: 'Boleta/Transacción Transbank', value: 'RECEIPT'},
        {text: 'Factura', value: 'INVOICE'},
        {text: 'Imagen del producto', value: 'PRODUCT'},
        {text: 'Prueba de entrega', value: 'DELIVERY_TEST'},
        {text: 'Otros', value: 'DEFAULT'},
        {text: 'Todos', value: ''}
      ]
    }
  },
  computed: {
    viewArray() {
      return this.attachments ? this.viewAttachment !== '' ? this.attachments.filter(el => el.attachment_type_code === this.viewAttachment) : [...this.attachments] : []
    }
  },
  methods: {
    getIcon(mimeType) {
      let icon = ''
      switch (mimeType) {
      case 'application/pdf':
      case '.pdf':
        icon = 'pdfIcon'
        break
      case 'image/jpeg':
      case 'image/png':
      case '.png':
      case '.jpeg':
      case '.jpg':
        icon = 'imageIcon'
        break
      default:
        icon = 'documentIcon'
        break
      }
      return icon
    },
    setArrayAttachment(source) {
      this.viewAttachment = source
    },
    formatDate(date) {
      return this.$options.filters.moment(date, 'DD-MM-YYYY')
    },
    openIframe(data) {
      this.indexFirst = this.viewArray.findIndex(el => el.id === data.id)
      this.$bvModal.show('modal-body-attachment')
    },
    updateTicket() {
      this.$emit('updateTicket', true)
    }
  }
}
</script>
<style lang="scss" scope>
.body--detail--attachment {
  .button-group {
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;

    button {
      border-radius: 0;
    }
    
    button:first-child {
      border-radius: 5px 0 0 5px;
    }

    button:last-child {
      border-radius: 0 5px 5px 0;
    }
  }

  .button-group-grid {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    gap: 10px;
    grid-template-columns: 200px auto;
  }
}
</style>
