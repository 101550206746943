<template>
  <div class="body--detail--attachment">
    <div  class="button-group admin--attachment">
      <b-button-group>
        <b-button v-for="(button, index) in buttonTables" :key="index"
          :pressed="button.active"
          v-b-toggle="button.collapseId"
          :variant="button.active ? 'primary' : 'link'" @click="activateButton(button)">
          {{button.text}}
        </b-button>
      </b-button-group>
    </div>
    <b-card>
      <b-collapse id="collapse-files" class="mt-2" visible accordion="my-accordion">
        <table-render :schema="schemaFiles" :rows="filesArray" :fixed="true" :striped="true" :small="true"
          :theadTrClass="'admin--head-attachment'" :tbodyTrClass="'admin--body-attachment'" :borderless="true">
          <template #actionsSlot="scope">
            <div class="attachment--button--container">
              <b-button variant="flat-success" v-b-tooltip.hover class="p-0" @click="openIframe(scope.rowdata)"><feather-icon icon="EyeIcon" /></b-button>
              <b-button variant="flat-success" v-b-tooltip.hover class="p-0" @click="downloadIframe(scope.rowdata)"><feather-icon icon="DownloadIcon" /></b-button>
              <b-button variant="flat-danger" v-b-tooltip.hover class="p-0" @click="openModalDelete(scope.rowdata)"><feather-icon icon="TrashIcon" /></b-button>
            </div>
          </template>
        </table-render>
      </b-collapse>
      <b-collapse id="collapse-record" class="mt-2" accordion="my-accordion">
        <table-render :schema="schema" :rows="viewArray" :fixed="true" :striped="true" :small="true"
          :theadTrClass="'admin--head-attachment'" :tbodyTrClass="'admin--body-attachment'" :borderless="true"
          v-show="buttonTables">
          <template #userSlot="scope">
            <p class="m-0">
              {{scope.rowdata.user.first_name}} {{scope.rowdata.user.last_name}}
            </p>
          </template>
          <template #statusSlot="scope">
            <div class="status--container">
              <div class="circle" :class="scope.rowdata.active ? '' : 'circle--red'"></div>
              <p class="m-0">{{scope.rowdata.active ? 'Activo' : 'Inactivo'}}</p>
            </div>
          </template>
          <template #extraFieldsSlot="scope">
            <div v-for="(element, index) in scope.rowdata.extra_fields" :key="index">
              {{element.value}}
            </div>
          </template>
        </table-render>
      </b-collapse>
    </b-card>
    <modal-detail-attachment-vue :arrayElements="filesArray" :indexFirst.sync="indexFirst"  ref="modalDetailAttachment"></modal-detail-attachment-vue>
  </div>
</template>
<script>
import { BButtonGroup, BCollapse, VBToggle } from 'bootstrap-vue'
import ModalDetailAttachmentVue from '@/views/modules/tickets/components/Modals/ModalDetailAttachment.vue'
import BaseServices from '@/store/services/index'
import axios from 'axios'
export default {
  props: ['ticket'],
  components: {
    BButtonGroup,
    BCollapse,
    ModalDetailAttachmentVue
  },
  directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      schema: [
        {label: 'Usuario', key: 'userSlot', useSlot: true},
        {label: 'Rol', key: 'user.role'},
        {label: 'Estado Archivo', key: 'statusSlot', useSlot: true},
        {label: 'Datos adicionales', key: 'extraFieldsSlot', useSlot: true},
        {label: 'Archivo', key: 'filename', sortable: true}
      ],
      schemaFiles: [
        {label: 'Nombre', key: 'filename'},
        {label: 'Tipo de archivo', key: 'attachment_type_name'},
        {label: '', key: 'actionsSlot', useSlot: true, class: ['col-2 p-0']}
      ],
      buttonTables: [
        {
          table: 'files',
          active: true,
          text: 'Archivos',
          collapseId: 'collapse-files'
        },
        {
          table: 'record',
          active: false,
          text: 'Historial',
          collapseId: 'collapse-record'
        }
      ],
      indexFirst: 0,
      baseService: new BaseServices(this)
    }
  },
  computed: {
    viewArray() {
      return this.ticket && this.ticket.attachments ? [...this.ticket.attachments] : []
    },
    filesArray() {
      return this.ticket && this.ticket.attachments ? [...this.ticket.attachments.filter((el) => el.active === true)] : []
    },
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  methods: {
    activateButton(button) {
      this.buttonTables.map((el) => {
        el.active = el.table === button.table
      })
    },
    openIframe(data) {
      this.indexFirst = this.filesArray.findIndex(el => el.id === data.id)
      this.$bvModal.show('modal-body-attachment')
    },
    async downloadIframe(data) {
      window.open(decodeURIComponent(data.url), 'Download')
      // const responseData = await this.downloadFile(data.url)
    },
    // async downloadFile(dataUrl) {
    //   return await
    //   axios({
    //     method: 'GET',
    //     url: decodeURIComponent(dataUrl),
    //     data: {},
    //     headers: {'Content-Disposition': 'attachment'}
    //   })
    //     .then(res => res.blob())
    //     .then(blob => {
    //       const file = window.URL.createObjectURL(blob)
    //       window.location.assign(file)
    //     })
    //     .catch((err) => {
    //       console.error(err)
    //     })
    // },
    openModalDelete(data) {
      const options = {
        title: 'Eliminar Archivo',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'outline-success',
        okTitle: 'Si',
        cancelTitle: 'No',
        cancelVariant: 'outline-danger',
        bodyClass: 'body-class-delete text-center',
        headerClass: 'header-class-delete',
        footerClass: 'footer-class-delete justify-content-center',
        hideHeaderClose: false,
        centered: true
      }
      this.$bvModal.msgBoxConfirm('¿Está seguro de que desea eliminar el archivo?', options)
        .then(value => {
          if (value) {
            this.deleteAttachment(data)
          }
        })
    },
    deleteAttachment(data) {
      const queryParams = {
        return_delivery_id: this.ticket.delivery.id,
        id: [data.id],
        first_name: this.dataUser.first_name,
        last_name: this.dataUser.last_name,
        email: this.dataUser.email,
        role: this.dataUser.role
      }
      const params = {
        ticket_id: this.ticket.delivery.id
      }
      this.baseService.callService('deleteFilesTicket', queryParams, params)
        .then(resp => {
          this.$emit('updateTicket', true)
        })
        .catch(err => {
          console.error(err)
          this.$alert(err)
        })
    }
  }
}
</script>
<style lang="scss" scope>
.body--detail--attachment {
  .button-group.admin--attachment {
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;

    .btn-group {
      padding: 4px;
      border-radius: 8px;
      background: #0442892b;
    }
    .btn-link {
      color: #6E6B7B;
    }
    button {
      min-width: 170px;
      height: 28px;
      padding: 0;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: -0.07999999821186066px;
      text-align: center;
    }
  }
  .card {
    margin: 0 0 10px;
  }
  .circle {
    background: #1E8E3E;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
  }
  .circle--red {
    background: red;
  }
  .status--container {
    display: grid;
    grid-template-columns: 1rem 1fr;
    gap: 5px;
    align-items: center;
  }
  .attachment--button--container {
    gap: 5px;
    display: flex;
    justify-content: flex-end;
  }

}
.modal-body.body-class-delete,
.modal-footer.footer-class-delete {
  border: 15px solid #F8FAFB;
}
.modal-header.header-class-delete {
  background: #FFFFFF;
}
.modal-footer.footer-class-delete button{
  width: 135px;
}
.modal-body.body-class-delete {
  border-bottom: 0;
}
.modal-footer.footer-class-delete{
  border-top: 0;
}

</style>
