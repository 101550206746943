import { render, staticRenderFns } from "./BodyDetailAttachment.vue?vue&type=template&id=81c0a188&"
import script from "./BodyDetailAttachment.vue?vue&type=script&lang=js&"
export * from "./BodyDetailAttachment.vue?vue&type=script&lang=js&"
import style0 from "./BodyDetailAttachment.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports