<template>
  <section>
    <b-card v-if="textMessage !== '' && showSendMessage" body-class="align-items-center d-flex flex-column w-100 message-box-detail">
      <p>{{textMessage}}</p>
      <b-button variant="success" @click="() => this.showSendMessage = false"> {{textButton}} </b-button>
    </b-card>
    <admin-body-detail-management v-else-if="dataUser.role === 'admin'" :ticket="ticket" :masterData="masterData" @updateTicket="updateTicket"></admin-body-detail-management>
    <body-detail-management v-else :ticket="ticket" :masterData="masterData" @updateTicket="updateTicket"></body-detail-management>
  </section>
</template>
<script>
import BodyDetailManagement from '@/views/modules/tickets/components/Detail/Answers/BodyDetailManagement.vue'
import AdminBodyDetailManagement from '@/views/modules/tickets/components/Detail/Answers/AdminBodyDetailManagement.vue'
export default {
  props: ['ticket', 'masterData'],
  components: {AdminBodyDetailManagement, BodyDetailManagement},
  data() {
    return {
      showSendMessage: true
    }
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    textMessage() {
      return this.ticket?.disabled_answers_notice?.message ? this.ticket?.disabled_answers_notice?.message : ''
    },
    textButton() {
      return this.ticket?.disabled_answers_notice?.button_text ? this.ticket?.disabled_answers_notice?.button_text : ''
    }
  },
  methods: {
    updateTicket() {
      this.$emit('updateTicket', true)
    }
  }
}
</script>
<style lang="scss">
  .message-box-detail {
    height: 20vh;
  }
</style>