<template>
  <section class="tab--b-card body--detail--chat-list">
    <div class="body--detail-chat-container">
      <div v-for="(message, index) in messages" :key="index" >
        <body-detail-message :message="message" :index="index" :ticket_id="ticket.delivery.id" @updateTicket="$emit('updateTicket', true)" :isTheModal="isTheModal" :carrierCode="ticket.carrier.code"></body-detail-message>
      </div>
    </div>
  </section>
</template>
<script>
import BodyDetailMessage from '@/views/modules/tickets/components/Detail/Message/BodyDetailMessage.vue'
export default {
  props: ['messages', 'ticket', 'isTheModal'],
  components: {BodyDetailMessage}
}
</script>
<style lang="scss" scoped>
  .body--detail--chat-list {
    max-height: 320px;
    overflow-y: scroll;
  }
  .body--detail-chat-container {
    display: grid;
    gap: 20px;
  }
</style>