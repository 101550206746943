<template>
  <b-tabs class="body--detail--ticket">
    <b-tab>
      <template #title><div><feather-icon icon="SettingsIcon"/> Gestión</div></template>
      <b-card class="tab-b-card">
        <answer-management :masterData="masterData" :ticket="ticket" @updateTicket="updateTicket"></answer-management>
      </b-card>
    </b-tab>
    <b-tab >
      <template #title><div><feather-icon icon="ClockIcon"/> Historial</div></template>
      <b-card class="tab--b-card">
        <body-detail-record :messages="ticket.messages" :ticket="ticket" @updateTicket="updateTicket"></body-detail-record>
      </b-card>
    </b-tab>
    <b-tab >
      <template #title><div><feather-icon icon="FileIcon"/> Adjuntos</div></template>
      <b-card class="tab--b-card">
        <body-detail-attachment :attachments="ticket.attachments" :attachmentTypes="masterData.attachment_types" :masterData="masterData" :ticket="ticket"  @updateTicket="updateTicket"></body-detail-attachment>
      </b-card>
    </b-tab>
  </b-tabs>
</template>
<script>
import BodyDetailRecord from '@/views/modules/tickets/components/Detail/Message/BodyDetailRecord.vue'
import BodyDetailAttachment from '@/views/modules/tickets/components/Detail/Attachments/BodyDetailAttachment.vue'
import AnswerManagement from '@/views/modules/tickets/components/Detail/Answers/AnswerManage.vue'
export default {
  props: ['ticket', 'masterData'],
  components: { BodyDetailRecord, BodyDetailAttachment, AnswerManagement},
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  methods: {
    updateTicket() {
      this.$emit('updateTicket', true)
    }
  }
}
</script>
<style lang="scss" scoped>
  .body--detail--ticket {
    .tab--b-card {
      background: #F7FAFC
    }
  }
</style>