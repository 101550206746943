<template>
  <section>
    <b-tabs>
      <b-tab>
        <template #title><div><feather-icon icon="SettingsIcon"/> Gestión</div></template>
        <management-tab :masterData="masterData" :ticket="ticket"></management-tab>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <div><feather-icon icon="ClockIcon"/> Historial <b-badge variant="info">Próximamente</b-badge></div>
        </template>
      </b-tab>
      <b-tab disabled>
        <template #title>
          <div><feather-icon icon="FileTextIcon"/> Detalle envío <b-badge variant="info">Próximamente</b-badge></div>
        </template>
      </b-tab>
    </b-tabs>
  </section>
</template>
<script>
import ManagementTab from '@/views/modules/tickets/components/Detail/Body/Tabs/MaganementTab.vue'
export default {
  props: ['ticket', 'masterData'],
  components: { ManagementTab }

}
</script>