<template>
  <section class="body--container--admin" v-if="checkMasterTicket">
    <div>
      <b-card class="p-0 m-0" body-class="p-sm-1">
        <div class="title-body-detail-record">
          <div>
            <p class="m-0">Mensajes 
              <b-dropdown size="lg" class="p-0"  variant="link" toggle-class="text-decoration-none p-0" no-caret>
                <template #button-content>
                  <b-icon icon="filter"></b-icon>
                </template>
                <b-dropdown-item  @click="() => filterMessage = ''" >Todo</b-dropdown-item>
                <b-dropdown-item  @click="() => filterMessage = 'left'" >Respuestas</b-dropdown-item>
                <b-dropdown-item  @click="() => filterMessage = 'center'" >Tracking</b-dropdown-item>
              </b-dropdown>
            </p>
          </div>
          <b-button variant="link" class="p-0" disabled>
            <b-badge variant="primary"> <b-icon icon="envelope-open" aria-hidden="true" scale="1"></b-icon> {{textRead}}</b-badge>
          </b-button>
          <div>
            <b-button variant="link" class="p-0" disabled v-if="textStatus !== ''">
              <b-badge variant="warning">{{textStatus}}</b-badge>
            </b-button>
          </div>
          <b-button v-b-modal.modal-detail-record variant="link" class="p-0"><b-icon icon="arrows-fullscreen" aria-hidden="true" scale="1" variant="primary"/></b-button>
        </div>
        <body-detail-record :messages="arrayMessages" :ticket="ticket" @updateTicket="updateTicket"></body-detail-record>
      </b-card>
      <answer-management :masterData="masterData" :ticket="ticket" @updateTicket="updateTicket"></answer-management>
    </div>
    <div>
      <admin-accordion-section :ticket="ticket" :masterData="masterData"  @updateTicket="updateTicket"></admin-accordion-section>
    </div>
    <b-modal id="modal-detail-record" centered title="Mensajes" hide-footer title-class="text-left title-modal-detail-record" modal-class="modal-detail-record" size="lg">
      <template #modal-header>
        <p class="m-0">Mensajes 
          <b-dropdown size="lg" class="p-0"  variant="link" toggle-class="text-decoration-none p-0" no-caret>
            <template #button-content>
              <b-icon icon="filter"></b-icon>
            </template>
            <b-dropdown-item  @click="() => filterMessage = ''" >Todo</b-dropdown-item>
            <b-dropdown-item  @click="() => filterMessage = 'left'" >Respuestas</b-dropdown-item>
            <b-dropdown-item  @click="() => filterMessage = 'center'" >Tracking</b-dropdown-item>
          </b-dropdown>
        </p>
        <b-button variant="link" class="p-0" @click="closeModal"><icons :icon="'expandArrowsInverseIcon'" :size="sizeIcon" ></icons></b-button>
      </template>
      <body-detail-record :messages="arrayMessages" :ticket="ticket" @updateTicket="updateTicket" :isTheModal="true"></body-detail-record>
    </b-modal>
  </section>
</template>
<script>
import BodyDetailRecord from '@/views/modules/tickets/components/Detail/Message/BodyDetailRecord.vue'
import AdminAccordionSection from '@/views/modules/tickets/components/Detail/Body/AdminAccordionSection.vue'
import AnswerManagement from '@/views/modules/tickets/components/Detail/Answers/AnswerManage.vue'
import Icons from '@/Icons/Icons.vue'
import {
  BIcon,
  BIconArrowsFullscreen,
  BIconEnvelopeOpen,
  BIconFilter
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['ticket', 'masterData'],
  components: { BodyDetailRecord, AdminAccordionSection, BIcon, BIconArrowsFullscreen, BIconEnvelopeOpen, AnswerManagement, Icons, BIconFilter},
  data() {
    return {
      sizeIcon: {
        width: '20px',
        height: '20px'
      },
      filterMessage: ''
    }
  },
  computed: {
    checkMasterTicket() {
      return !!this.masterData && !!this.ticket
    },
    textStatus() {
      return this.ticket && this.ticket.current_type && this.ticket.current_type.name ? this.ticket.current_type.name : ''
    },
    textRead() {
      return this.ticket.last_message && this.ticket.last_message.read ? 'Leído' : 'No Leído'
    },
    arrayMessages() {
      return this.filterMessage !== '' ? this.ticket?.management_record.filter(el => 
        this.filterMessage === 'center' ? el.align === 'center' : el.align !== 'center'
      ) : this.ticket?.management_record ?? []
    }
  },
  mounted() {
    document.getElementById('loading-bg').style.display = 'block'
    this.checkMasterData()
  },
  methods: {
    checkMasterData() {
      if (this.checkMasterTicket) {
        document.getElementById('loading-bg').style.display = 'none'
      } else {
        setTimeout(() => {
          this.checkMasterData()
        }, 1000)
      }
    },
    closeModal() {
      this.$bvModal.hide('modal-detail-record')
    },
    updateTicket() {
      this.$emit('updateTicket', true)
    }
  }
}
</script>
<style lang="scss">
  .body--container--admin {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    .body--detail--chat-list,
    .body-management-admin {
      margin: 0;
    }
  }
  .body--container--admin .collapse--container {
    margin: 0;
  }
  .body--container--admin .collapse--container .collapse--container-button {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #044389
  }
  .body--container--admin .collapse--container .collapse--container-button + .collapse {
    margin: 5px 0 0;
  }
  .title-body-detail-record {
    display: grid;
    grid-template-columns: 1fr repeat(3, auto);
    gap: 5px;
    padding-bottom: 10px;
  }
  .title-body-detail-record p,
  .modal-detail-record .title-modal-detail-record {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #044389;
  }
  .modal-detail-record {
    .body--detail--chat-list {
      max-height: 85vh;
    }
  }
</style>