<template>
  <b-card class="body-management-admin">
    <h1 class="title-body-management">Respuestas Predeterminadas</h1>
    <form-render :form.sync="form" :fields="fields" @send="onAccept" :key="keyFormRender">
      <template #messageDivisorSlot>
        <h1 class="title-body-management">Puedes cambiar el estado del ticket al responder</h1>
      </template>
      <template #buttonSlot>
        <div class="d-flex justify-content-end">
          <b-button type="submit" variant="warning" :disabled="loading">
            Enviar Respuesta
          </b-button>
        </div>
      </template>
    </form-render>
  </b-card>
</template>
<script>
import BaseServices from '@/store/services/index'
export default {
  props: ['ticket', 'masterData'],
  data() {
    return {
      baseService: new BaseServices(this),
      form: {},
      fields: [],
      keyFormRender: 0,
      viewForm: false,
      loading: false,
      optionsTicketStage: [],
      optionsTicketStatuses: []
    }
  },
  mounted() {
    this.checkConditions()
  },
  watch: {
  },
  computed: {
    optionsDefaultAnswer() {
      return !!this.masterData.answers_info && this.masterData.answers_info.Answers ? this.masterData.answers_info.Answers.map(element => ({
        ...element,
        value: element.id,
        text: element.name
      })) : []
    },
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  methods: {
    changeDefault(data) {
      this.form.message = this.form && this.form[data] && this.form[data].message ?  this.form[data].message : ''
    },
    changeStage(data) {
      this.form.ticketStatus = null
      if (this.form[data]) {
        this.filterStatuses(this.form[data].id)
      }
      this.keyFormRender++
    },
    checkConditions() {
      !!this.optionsDefaultAnswer && this.optionsDefaultAnswer.length > 0 ? this.setStatus(this.masterData.ticket_statuses) : setTimeout(() => {
        this.checkConditions()
      }, 1000)
    },
    filterStatuses(stage) {
      const options = this.optionsTicketStatuses.filter((el) => el.ticket_stage_code === stage)
      this.fields.map((el) => {
        if (el.name === 'ticketStatus') {
          el.options = [...options]
        }
      })
      this.keyFormRender++
    },
    onAccept(data) {
      const dataSend = {
        _document: this.ticket._document,
        delivery: this.ticket.id,
        user: {
          first_name: this.dataUser.first_name,
          last_name: this.dataUser.last_name,
          email: this.dataUser.email,
          role: this.dataUser.role
        },
        message: this.form.message
      }
      if (this.form.ticketStatus) dataSend.status = {
        code: this.form.ticketStatus.code,
        agent_name: this.form.ticketStatus.agent_name,
        client_name: this.form.ticketStatus.client_name,
        sub_status_code: this.form.ticketStatus.sub_status_code,
        sub_status_name: this.form.ticketStatus.sub_status_name
      }
      this.postAnswerTicket(dataSend)
    },
    postAnswerTicket(formSend) {
      this.loading = true
      this.baseService.callService('postResponseTicket', formSend, { ticket_id: formSend.delivery })
        .then(resp => {
          this.$emit('updateTicket', true)
          this.$success(resp.message)
          this.resetVariables()
        })
        .catch(err => {
          console.error(err)
          this.$alert(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetVariables() {
      this.form = {}
    },
    setDefaultValues() {
      if (this.ticket && this.ticket.current_stage && this.ticket.current_stage.code) {
        const valueStage = this.optionsTicketStage.filter((el) => el.id === this.ticket.current_stage.code)[0]
        this.form.ticketStage = valueStage
        this.filterStatuses(this.ticket.current_stage.code)
      }
      if (this.ticket && this.ticket.current_status && this.ticket.current_status.sub_status_code) {
        const valueStatus = this.optionsTicketStatuses.filter((el) => el.id === this.ticket.current_status.sub_status_code)[0]
        this.form.ticketStatus = valueStatus
      }
    },
    setFormFields() {
      this.fields = [
        { fieldType: 'FieldSelect', name: 'defaultAnswer', label: '', placeholder: 'Seleccione una respuesta', containerClass: 'col-sm-12 container-info', options: this.optionsDefaultAnswer, change: this.changeDefault },
        { fieldType: 'FieldTextarea', name: 'message', label: '', placeholder: 'El mensaje que escribas debe tener todos los antecedentes posibles para dar soluciones al ticket ...', containerClass: 'col-sm-12 container-info', validation: 'required' },
        { name: 'messageDivisorSlot', containerClass: 'col-sm-12 container-info', useSlot: true },
        { fieldType: 'FieldSelect', name: 'ticketStage', label: '', placeholder: '', containerClass: 'col-sm-12 container-info', options: this.optionsTicketStage, change: this.changeStage },
        { fieldType: 'FieldSelect', name: 'ticketStatus', label: '', placeholder: '', containerClass: 'col-sm-12 container-info', options: []},
        { name: 'buttonSlot', useSlot: true, containerClass: 'col-sm-12 container-info' }
      ]
      this.setDefaultValues()
    },
    setStatus(ticket_statuses) {
      const guideTicketStages = []
      this.optionsTicketStatuses = ticket_statuses ? ticket_statuses.flatMap(el => {
        let response = []
        const conditionalStage = guideTicketStages.includes(el.ticket_stage_code)
        if (!conditionalStage) {
          guideTicketStages.push(el.ticket_stage_code)
          this.optionsTicketStage.push({
            text: el.ticket_stage_name,
            id: el.ticket_stage_code
          })
        }
        for (let index = 0; index < el.ticket_sub_statuses.length; index++) {
          const element = el.ticket_sub_statuses[index]
          response = [...response, {
            ...el,
            id: element.code,
            sub_status_code: element.code,
            sub_status_name: element.name,
            text: `${el.complete_name} - ${element.name}`
          }]
        }
        return response
      }) : []
      this.setFormFields()
    }
  }
}
</script>
<style lang="scss">
  .body-management-admin {
    .title-body-management {
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-align: left;
      color: #044389;
    }
  }
</style>