<template>
  <section>
    <div v-if="textNotes && !isEditing">
      <p>{{textNotes}}</p>
      <b-button @click="changeToEdit">Editar</b-button>
    </div>
    <div v-if="isEditing">
      <b-form @submit="onOk" @reset="onReset">
        <b-form-group
          label="Nota"
          label-for="input-note"
          description="Nota del ticket"
        >
          <b-form-input
              id="input-note"
              v-model="form.note"
              type="text"
              placeholder="Ingresa la nota"
              :value="textNotes"
              required>
          </b-form-input>
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button type="submit" variant="success">Guardar</b-button>
          <b-button type="reset" variant="warning" class="ml-1">Cancelar</b-button>
        </div>
      </b-form>
    </div>
  </section>
</template>
<script>
export default {
  props: ['ticket'],
  data() {
    return {
      form: {},
      isEditing: false
    }
  },
  computed: {
    textNotes() {
      return this.ticket?.agent_notes?.notes ? this.ticket?.agent_notes?.notes : 'Sin notas'
    }
  },
  methods: {
    onReset() {
      this.form = {}
      this.isEditing = false
    },
    changeToEdit() {
      this.isEditing = true
      this.form.note = this.textNotes
    },
    onOk() {
      const sendData = {
        notes: this.form.note
      }
      this.$emit('saveDataTicket', {agent_notes: {...sendData}})
      this.onReset()
    }
  }
}
</script>