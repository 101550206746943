<template>
  <b-card>
    <b-form-group label-class="text-primary font-weight-bolder" label="Seleccione que desea hacer con el envío :" v-slot="{ ariaDescribedby }" class="body--detail--radio-group">
      <b-form-radio-group
        v-model="newTypeTicket"
        :options="ticketTypes"
        :aria-describedby="ariaDescribedby"
        name="radio-options"
        @change="changeTypeTicket"
        class="body--detail--radio-group"
      ></b-form-radio-group>
    </b-form-group>
    <extra-fields :extraField="objectExtraField"
        :masterData="masterData" :options="optionsExtraField" @updateExtraField="onAccept" :ticket="ticket">
    </extra-fields>
  </b-card>
</template>
<script>
import BaseServices from '@/store/services/index'
import ExtraFields from '@/views/modules/tickets/components/ExtraFields/ExtraFields.vue'
export default {
  props: ['ticket', 'masterData'],
  components: {ExtraFields},
  data() {
    return {
      baseService: new BaseServices(this),
      newTypeTicket: {},
      keyFormRender: 0,
      viewForm: false,
      fileToSendTicket: [],
      optionsExtraField: {
        nameButton: 'Confirmar',
        loading: true
      },
      objectExtraField: {}
    }
  },
  computed: {
    ticketTypes() {
      const optionsReturn = this.masterData && this.masterData.ticket_types && this.masterData.ticket_types.length > 0 ? this.masterData.ticket_types.map(el => ({
        ...el,
        value: el.code,
        text: el.name
      })) : []
      return optionsReturn
    },
    dataUser() {
      return this.$session.get('cas_user')
    },
    optionWarehouse() {
      return this.masterData.warehouse ? this.masterData.warehouse.map(element => ({
        ...element,
        value: element.id ?? element.identifier ?? element.code,
        text: element.text ?? element.name
      })) : []
    }
  },
  methods: {
    changeTypeTicket (value) {
      this.optionsExtraField.loading = false
      this.viewForm = true
      this.objectExtraField = this.masterData.ticket_types.find((element) => element.code === value)
    },
    onAccept(data) {
      this.optionsExtraField.loading = true
      const formSend = {
        _document: this.ticket._document,
        delivery: this.ticket.delivery.id,
        user: {
          first_name: this.dataUser.first_name,
          last_name: this.dataUser.last_name,
          email: this.dataUser.email,
          role: this.dataUser.role
        },
        type: {
          code: data.ticket_type_code.code,
          name: data.ticket_type_code.name
        },
        extra_fields: data.extra_fields.data_organized.extra_fields,
        message: data.extra_fields.message
      }
      this.fileToSendTicket = data.extra_fields.data_organized.fileToSend
      this.postAnswerTicket(formSend)
    },

    async postAnswerTicket(formSend) {
      try {
        const resp = await this.baseService.callService('postResponseTicket', formSend, { ticket_id: formSend.delivery })
        if (this.fileToSendTicket.length > 0) {
          for (let index = 0; index < this.fileToSendTicket.length; index++) {
            const element = this.fileToSendTicket[index]
            await this.delay(1000)
            await this.updateFiles(element)
          }
          this.$bvModal.hide('modalNewTicket')
          this.$success('Archivos y respuesta subidos de forma exitosa')
        } else {
          this.$success(resp.message)
          this.resetVariables()
        }
      } catch (err) {
        console.error(err)
        this.$alert(err)
      } finally {
        this.optionsExtraField.loading = false
        this.$emit('updateTicket', true)
      }
    },
    async updateFiles(element) {
      try {
        await this.baseService.callSpecialUploadFile('updateFiles', element.files, { ticket_id: this.ticket.delivery.id }, this.$session.get('cas_user'), element.queryParams, 'files')
      } catch (err) {
        console.error(err)
      }
    },
    delay(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
    resetVariables() {
      this.newTypeTicket = {}
      this.objectExtraField = {}
    }
  }
}
</script>
<style lang="scss">
  .body--detail--radio-group {
    display: grid;
    gap: 10px;
    margin-bottom: 20px;
  }
</style>