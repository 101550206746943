<template>
  <section v-if="checkMasterTicket">
    <form-render :form.sync="form" :fields="fields" :key="keyFormRender" @send="onAccept">
      <template #buttonsGroupSlot>
        <div class="button--container-claim">
          <b-button variant="outline-primary" title="Editar" @click="changeDisable" v-show="!flagDisabled">Editar</b-button>
          <b-button variant="outline-danger" title="Cancelar" @click="setDataDefault" v-show="flagDisabled" :disabled="!flagDisabled || checkLoading">Cancelar</b-button>
          <b-button variant="warning" title="Guardar" v-show="flagDisabled" :disabled="!flagDisabled || checkLoading" type="submit">Guardar</b-button>
        </div>
      </template>
    </form-render>
  </section>
</template>
<script>
export default {
  props: ['ticket', 'masterData', 'loading', 'formActive'],
  data() {
    return {
      form: {},
      fields: [],
      visible: false,
      keyFormRender: 0,
      optionsForm: {
        claimOptions: [
          {id: 'Apelaciones', text: 'Apelaciones'},
          {id: 'Aprobado Courier', text: 'Aprobado Courier'},
          {id: 'Perdidas', text: 'Perdidas'},
          {id: 'Sin Información', text: 'Sin Información'}
        ],
        obsOptions: [
          {id: 'Con finiquito', text: 'Con finiquito'},
          {id: 'Sin finiquito', text: 'Sin finiquito'},
          {id: 'Re-estudio', text: 'Re-estudio'},
          {id: 'Error Ejecutivo', text: 'Error Ejecutivo'},
          {id: 'Error Políticas Op', text: 'Error Políticas Op'},
          {id: 'Error Sistema', text: 'Error Sistema'},
          {id: 'Acuerdo Comercial', text: 'Acuerdo Comercial'}
        ]
      },
      flagDisabled: true
    }
  },
  mounted() {
    this.setForm()
  },
  computed: {
    checkMasterTicket() {
      return !!this.ticket && !!this.masterData
    },
    checkLoading() {
      return this.loading === 'loading'
    },
    options() {
      return {
        compensation_status: [...this.optionsForm.claimOptions],
        complaint_status_appreciation: [...this.optionsForm.claimOptions],
        compensation_observation: [...this.optionsForm.obsOptions],
        complaint_status_appreciation_detail: [...this.optionsForm.obsOptions]
      }
    }
  },
  watch: {
    formActive(value) {
      if (value !== 'claim-status' && this.flagDisabled) this.disableFields()
    }
  },
  methods: {
    setForm() {
      this.fields = [
        {fieldType: 'FieldDatepicker', name: 'date', label: 'Fecha de estado actual', containerClass: 'col-12 col-md-6 container-info', clearable: true},
        {fieldType: 'FieldSelect', name: 'compensation_status', label: 'Estado actual de indemnización', containerClass: 'col-12 col-md-6 container-info', clearable: true, options: this.options.compensation_status},
        {fieldType: 'FieldSelect', name: 'compensation_observation', label: 'Obs de estado actual', containerClass: 'col-12 col-md-6 container-info', clearable: true, options: this.options.compensation_observation},
        {fieldType: 'FieldInput', name: 'compensation_detail', label: 'Detalle de indemnización courier', containerClass: 'col-12 col-md-6 container-info'},
        {fieldType: 'FieldSelect', name: 'complaint_status_appreciation', label: 'Estado inicial de indemnización', containerClass: 'col-12 col-md-6 collapse--space', clearable: true, options: this.options.complaint_status_appreciation},
        {fieldType: 'FieldSelect', name: 'complaint_status_appreciation_detail', label: 'Obs de estado inicial', containerClass: 'col-12 col-md-6 container-info', clearable: true, options: this.options.complaint_status_appreciation_detail},
        {name: 'buttonsGroupSlot', useSlot: true, containerClass: 'col-12'}
      ]
      this.setDataDefault()
    },
    setDataDefault() {
      this.form = {}
      this.fields.map(el => {
        if (this.ticket.complaint_status_data && el.name !== 'buttonsGroupSlot') {
          if (el.fieldType === 'FieldSelect') {
            this.form[el.name] = this.options[el.name].filter((element) => element.id === this.ticket.complaint_status_data[el.name])[0]
          } else {
            this.form[el.name] = this.ticket.complaint_status_data[el.name] ?? null
          }
        } else {
          this.form[el.name] = null
        }
      })
      this.changeDisable()
    },
    changeDisable() {
      this.$emit('editIn', !this.flagDisabled ? 'claim-status' : 'cancel')
      this.disableFields()
    },
    disableFields() {
      this.fields.map(el => {
        el.disabled = this.flagDisabled
      })
      this.flagDisabled = !this.flagDisabled
      this.keyFormRender++
    },
    onAccept() {
      this.changeDisable()
      const sendData = {
        compensation_status_date: this.form.compensation_status_date,
        compensation_status: this.form.compensation_status?.id,
        compensation_observation: this.form.compensation_observation?.id,
        compensation_detail: this.form.compensation_detail,
        complaint_status_appreciation: this.form.complaint_status_appreciation?.id,
        complaint_status_appreciation_detail: this.form.complaint_status_appreciation_detail?.id
      }
      this.$emit('saveDataTicket', {complaint_status_data: {...sendData}, overwrite_complaint_data: true})
    }
  }
}
</script>