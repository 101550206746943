<template>
  <section class="detail--ticket--container">
    <div v-if="!loading">
      <b-card v-if="conditionViewTicket && dataUser.role !== 'admin'" class="mb-0">
        <head-detail :ticket="ticket" :masterData="masterData" @updateTicket="getAllData"></head-detail>
        <body-detail :ticket="ticket" :masterData="masterData" @updateTicket="getAllData"></body-detail>
      </b-card>
      <b-card v-if="conditionViewTicket && dataUser.role === 'admin'" class="mb-0 detail--ticket--admin">
        <head-detail :ticket="ticket" :masterData="masterData" @updateTicket="getAllData"></head-detail>
        <body-detail-admin :ticket="ticket" :masterData="masterData" @updateTicket="getAllData"></body-detail-admin>
      </b-card>
      <b-overlay :show="viewConfig.disabled_answers_notice.message !== '' && !!showMessage" opacity="1" variant="white" no-wrap>
        <template #overlay>
          <div class="text-center">
            <h1>{{viewConfig.disabled_answers_notice.message}}</h1>
            <b-button variant="success" @click="hideMessage">{{viewConfig.disabled_answers_notice.button_text}}</b-button>
          </div>
        </template>
      </b-overlay>
    </div>    
    <skeleton-ticket-loader v-else/>
  </section>
</template>
<script>
import BaseServices from '@/store/services/index'
import { ticketDetail } from '@/views/modules/tickets/models/ticketDetail'
import HeadDetail from '@/views/modules/tickets/components/Detail/Head/HeadDetail.vue'
import BodyDetail from '@/views/modules/tickets/components/Detail/Body/BodyDetail.vue'
import BodyDetailAdmin from '@/views/modules/tickets/components/Detail/Body/AdminBodyDetail.vue'
import SkeletonTicketLoader from './SkeletonTicketLoader.vue'

export default {
  props: ['ticketIdModal'],
  data() {
    return {
      baseService: new BaseServices(this),
      ticket: ticketDetail,
      idTicket: 0,
      masterData: {},
      showMessage: false,
      viewConfig: {
        disabled_answers_notice: {
          button_text: '',
          message: ''
        }
      },
      loading: true
    }
  },
  components: {HeadDetail, BodyDetail, BodyDetailAdmin, SkeletonTicketLoader},
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    conditionViewTicket() {
      return !!this.ticket && !!this.dataUser
    }
  },
  mounted() {
    this.idTicket = this.$route.params.id ?? this.ticketIdModal
    this.loading = true
    this.checkConditionUser()
  },
  methods: {
    checkConditionUser() {
      !!this.dataUser ? this.getAllData() : setTimeout(() => {
        this.$router.go(this.$router.currentRoute)
      }, 1000)
    },
    getAllData() {
      const params = {
        ticket_id: this.idTicket
      }
      const queryParams = {
        user_email: this.dataUser.email ?? '',
        timezone: this.dataUser.timezone ?? 'America/Santiago'
      }
      this.baseService.callService('getTicketsDetail', queryParams, params)
        .then(response => {
          this.ticket = {...response.data[0]}
          this.viewConfig = {...response.view_config}
        })
        .catch((err) => {
          this.$alert(err)
        })
        .finally(() => {
          this.getMasterData()
        })
    },
    getMasterData() {
      const params = {
        ticket_id: this.idTicket
      }
      const country = this.ticket && this.ticket.carrier && this.ticket.carrier.country ? this.ticket.carrier.country : 'CL'
      const queryParams = {
        ticket_exists: true,
        delivery_id: this.idTicket
      }
      this.baseService.callService('getTicketsMasterData', queryParams, params)
        .then(response => {
          this.masterData = response.data
        })
        .catch((err) => {
          this.$alert(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    hideMessage() {
      this.showMessage = false
    }
  }
}
</script>
<style lang="scss">
  .detail--ticket--container {
    max-height: 100vh;
    overflow: scroll;
  }
  .detail--ticket--admin {
    background-color: #F7FAFC;
  }
</style>