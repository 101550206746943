<template>
  <section>
    <div class="mb-1">
      En la lista
      <b-dropdown @shown="() => stageToggle= false" @hidden="() => stageToggle= true" variant="link" class="p-0 mr-02" :no-caret="true" toggle-class="p-0" :disabled="loading || dataUser.role!=='admin'">
        <template #button-content>
          {{textStage}}
          <b-icon :icon="stageToggle ? 'chevron-compact-down' : 'chevron-compact-up'"
          aria-hidden="true" scale="1" variant="primary"/>
        </template>
        <b-dropdown-item v-for="(stage, index) in optionsTicketStage" :key="index"  @click="changeStage(stage)">{{stage.text}}</b-dropdown-item>
      </b-dropdown>
      <b-badge variant="warning">
        <b-dropdown  @shown="() => stateToggle= false" @hidden="() => stateToggle= true" variant="link" class="p-0 mr-02" :no-caret="true" toggle-class="p-0" :disabled="!selectStage.id || loading || dataUser.role!=='admin'">
          <template #button-content>
            <div class="align-items-center d-flex">
              <p class="m-0 pr-05 text-white">{{textStatus}}</p>
              <b-icon :icon="stateToggle ? 'chevron-compact-down' : 'chevron-compact-up'"
              aria-hidden="true" scale="2" variant="white"/>
            </div>
           </template>
          <b-dropdown-item v-for="(status, index) in optionsTicketStatuses" :key="index"  @click="changeStatus(status)">{{status.text}}</b-dropdown-item>
        </b-dropdown>
      </b-badge>
    </div>
    <div class="d-flex">
      <div class="pr-07">
        <b-icon variant="primary" icon="credit-card" scale="1.5" ></b-icon>
      </div>
      <div>
        <h1 class="h3 text-primary">
          <span>{{ !!ticket.current_status && !!ticket.current_status.client_name ? ticket.current_status.client_name : '' }}</span>  
        </h1>
        <h2 class="h5 text-warning"><span>{{ !!ticket.current_status && !!ticket.current_status.sub_status_name ? ticket.current_status.sub_status_name : '' }}</span></h2>
      </div>
    </div>
    <head-detail-answer v-if="!!ticket && !!ticket.last_message && !!ticket.last_message.resolution_date" :resolution_date="ticket.last_message.resolution_date"></head-detail-answer>
    <head-detail-resume :ticket="ticket"></head-detail-resume>
  </section>
</template>
<script>
import HeadDetailAnswer from '@/views/modules/tickets/components/Detail/Head/HeadDetailAnswer.vue'
import HeadDetailResume from '@/views/modules/tickets/components/Detail/Head/HeadDetailResume.vue'
import BaseServices from '@/store/services/index'
import {
  BIcon,
  BIconCreditCard,
  BIconChevronCompactUp,
  BIconChevronCompactDown
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['ticket', 'masterData'],
  components: {HeadDetailAnswer, HeadDetailResume, BIcon, BIconCreditCard, BIconChevronCompactUp, BIconChevronCompactDown},
  data() {
    return {
      baseService: new BaseServices(this),
      stageToggle: true,
      stateToggle: true,
      selectStage: {},
      selectStatus: {},
      loading: false
    }
  },
  mounted() {
    this.resetValue()
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    },
    optionsTicketStatuses() {
      const options = this.masterData.ticket_statuses ? this.masterData.ticket_statuses.flatMap(el => {
        let response = []
        for (let index = 0; index < el.ticket_sub_statuses.length; index++) {
          const element = el.ticket_sub_statuses[index]
          response = [...response, {
            ...el,
            id: element.code,
            sub_status_code: element.code,
            sub_status_name: element.name,
            text: `${el.client_name} - ${element.name}`
          }]
        }
        return response
      }).filter((el) => el.ticket_stage_code === this.selectStage.id) : []
      return options
    },
    optionsTicketStage() {
      const guideTicketStages = []
      return this.masterData.ticket_statuses ? this.masterData.ticket_statuses.reduce((acc, el) => {
        if (!guideTicketStages.includes(el.ticket_stage_code)) {
          guideTicketStages.push(el.ticket_stage_code)
          acc.push({
            text: el.ticket_stage_name,
            id: el.ticket_stage_code
          })
        }
        return acc
      }, []) : []
    },
    textStage() {
      return this.selectStage.text ? this.selectStage.text : this.currentStageName
    },
    currentStageName() {
      return this.ticket && this.ticket.current_stage && this.ticket.current_stage.name ? this.ticket.current_stage.name : ''
    },
    clientName() {
      return this.ticket && this.ticket.current_status && this.ticket.current_status.client_name ? this.ticket.current_status.client_name : ''
    },
    subStatusName() {
      return this.ticket && this.ticket.current_status && this.ticket.current_status.sub_status_name ? this.ticket.current_status.sub_status_name : ''
    },
    textStatus() {
      return this.selectStatus.text || `${this.clientName} - ${this.subStatusName}`
    }
  },
  methods: {
    changeStage(data) {
      this.selectStage = data
    },
    changeStatus(data) {
      this.selectStatus = data
      this.loading = true
      const dataSend = {
        _document: this.ticket._document,
        delivery_id: this.ticket.id,
        user: {
          first_name: this.dataUser.first_name,
          last_name: this.dataUser.last_name,
          email: this.dataUser.email,
          role: this.dataUser.role
        },
        current_sub_status: {
          code: data.id,
          name:  data.sub_status_name
        },
        current_status: {
          code: data.code,
          agent_name: data.agent_name,
          client_name: data.client_name
        }
      }
      this.baseService.callService('upgradeTickets', dataSend)
        .then(response => {
          this.resetValue()
          this.$emit('updateTicket', true)
        })
        .catch((err) => {
          this.$alert(err)
        })
        .finally(() => {
          this.loading = false 
        })
    },
    resetValue() {
      this.selectStage = {}
      this.selectStatus = {}
    }
  }
}
</script>