<template>
  <section class="form--container">
    <div v-for="(section, index) in sections" :key="index">
      <b-card class="collapse--container">
        <b-button :class="section.visible ? null : 'collapsed'"
                  :aria-expanded="section.visible ? 'true' : 'false'"
                  :aria-controls="section.id"
                  variant="link" class="p-0 collapse--container-button"
                  @click="toggleVisibility(section)">
          {{ section.title }}
          <b-icon :icon="section.visible ? 'chevron-up' : 'chevron-down'"
                  aria-hidden="true" scale="1" variant="primary"/>
        </b-button>
        <b-collapse :id="section.id" :visible="section.visible">
          <component :is="section.component"
                     :ticket="ticket"
                     :masterData="masterData"
                     :formActive="formActive"
                     @saveDataTicket="saveDataTicket"
                     @updateTicket="updateTicket"
                     @editIn="editIn"
                     :loading="loading"/>
        </b-collapse>
      </b-card>
    </div>
  </section>
</template>
<script>
import ComplaintData from '@/views/modules/tickets/components/Detail/Complaint/ComplaintData.vue'
import ClaimStatus from '@/views/modules/tickets/components/Detail/Complaint/ClaimStatus.vue'
import CompensationData from '@/views/modules/tickets/components/Detail/Complaint/CompensationData.vue'
import BaseServices from '@/store/services/index'
import AdminBodyDetailAttachment from '@/views/modules/tickets/components/Detail/Attachments/AdminBodyDetailAttachment.vue'
import UploadAttachmentsTicket from '@/views/modules/tickets/components/updateDocuments/uploadAttachmentsTicket.vue'
import BodyNotes from '@/views/modules/tickets/components/Detail/Message/BodyNotes.vue'
import { BCollapse, BButton, VBToggle, BIcon, BIconChevronUp, BIconChevronDown } from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['ticket', 'masterData'],
  components: {ComplaintData, ClaimStatus, CompensationData, BCollapse, BButton, BIcon, BIconChevronUp, BIconChevronDown, AdminBodyDetailAttachment, UploadAttachmentsTicket, BodyNotes},
  directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      baseService: new BaseServices(this),
      loading: 'noLoading',
      sections: [
        {
          id: 'attachment-upload-collapse-id',
          title: 'Subir archivos',
          component: 'upload-attachments-ticket',
          visible: false
        },
        {
          id: 'attachment-collapse-id',
          title: 'Registro de archivos',
          component: 'admin-body-detail-attachment',
          visible: false
        },
        {
          id: 'claim-collapse-id',
          title: 'Datos de reclamo',
          component: 'complaint-data',
          visible: false
        },
        {
          id: 'claimStatus-collapse-id',
          title: 'Estado de reclamo',
          component: 'claim-status',
          visible: false
        },
        {
          id: 'compensation-collapse-id',
          title: 'Datos de indemnización',
          component: 'compensation-data',
          visible: false
        },
        {
          id: 'notes-collapse-id',
          title: 'Notas',
          component: 'body-notes',
          visible: false
        }
      ],
      formActive: 'cancel'
    }
  },
  computed: {
    dataUser() {
      return this.$session.get('cas_user')
    }
  },
  methods: {
    editIn(data) {
      this.formActive = data
    },
    toggleVisibility(section) {
      this.sections.map((el) => {
        el.visible = el.id === section.id ? !section.visible : false
      })
    },
    saveDataTicket(data) {
      this.loading = 'loading'
      const formSend = {
        _document: this.ticket._document,
        delivery_id: this.ticket.delivery.id,
        user: {
          first_name: this.dataUser.first_name,
          last_name: this.dataUser.last_name,
          email: this.dataUser.email,
          role: this.dataUser.role
        },
        ...data
      }
      this.baseService.callService('upgradeTickets', formSend, null)
        .then(response => {
          this.$success('Ticket editado correctamente')
        })
        .catch((err) => {
          this.$alert(err)
          console.error(err)
        })
        .finally(() => {
          this.loading = 'noLoading'
          this.updateTicket()
        })
    },
    updateTicket() {
      this.$emit('updateTicket', true)
    }
  }
}
</script>
<style lang="scss">
  .form--container {
    display: grid;
    gap: 10px;
    align-content: baseline;
  }
  [dir] .admin--head-attachment th,
  [dir] .admin--body-attachment td,
  [dir=ltr] #collapse-files .table.b-table > * > tr.admin--body-attachment > *:last-child {
    padding: 0 !important;
  }
  .admin--head-attachment th {
    background: white !important;
    border-bottom: 1px solid #0443891F !important;
    padding: 0 0 5px !important;

  }
  .table.b-table > thead > tr.admin--head-attachment > * > *,
  .table.b-table > tbody > tr.admin--body-attachment > * > * {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 500;
    line-height: 12.19px;
    text-align: left;
  }
</style>