<template>
    <b-modal id="modal-body-attachment" size="xl" hide-footer @show="openModal" title="Visualizador de archivos">
      <div class="carousel--container--ticket">
        <div>
          <b-button variant="link" @click="carouselIndex('less')" v-if="arrayElements.length !== 0 && indexViewFirst !== 0" >
            <b-icon icon="arrow-left-short" aria-hidden="true" scale="4" variant="primary"></b-icon>          
          </b-button>
        </div>
        <div class="w-100">
          <img v-if="mimetypeAttachment == 'iframeImage'" :src="iframeUrl" alt="" class="preview-iframe">
          <iframe v-if="mimetypeAttachment == 'iframePdf'" class="preview-iframe" :src="iframeUrl" title="previsualización">
            Archivo no soportado lo puedes descargar en:
            <a :href="iframeUrl" target="_blank">Aquí</a>
          </iframe>
          <div v-if="mimetypeAttachment == 'another'" class="preview-iframe">
            <p>Archivo no soportado lo puedes descargar:
              <a :href="iframeUrl" target="_blank">Aquí</a>
            </p>
          </div>
        </div>
        <div>
          <b-button variant="link" @click="carouselIndex('more')" v-if="arrayElements.length !== 0 && indexViewFirst < arrayElements.length - 1">
            <b-icon icon="arrow-right-short" aria-hidden="true" scale="4" variant="primary"></b-icon>          
          </b-button>
        </div>
      </div>
      <div class="carousel--dots--container">
        <div class="carousel--dots--ticket">
          <b-button class="p-0" v-for="(element, index) of arrayElements" :key="index" variant="link" @click="carouselIndex('dot', index)">
            <b-icon icon="dot" aria-hidden="true" :scale="indexViewFirst === index ? 4 : 2" variant="primary"></b-icon>
          </b-button>
        </div>
      </div>
    </b-modal>
</template>
<script>
import {
  BIcon,
  BIconArrowLeftShort,
  BIconArrowRightShort,
  BIconDot
} from 'bootstrap-vue'
/* eslint-disable vue/no-unused-components */
export default {
  props: ['arrayElements', 'indexFirst'],
  components: {BIcon, BIconArrowLeftShort, BIconArrowRightShort, BIconDot},
  data() {
    return {
      indexViewFirst: 0,
      iframeUrl: '',
      mimetypeAttachment: '',
      elementSelected: {}
    }
  },
  watch: {
    'indexFirst': {
      handler(value) {
        this.changeIndex(value)
      },
      immediate: true
    }
  },
  methods: {
    changeIndex(value) {
      this.indexViewFirst = value ?? 0
      this.setDataModal()
    },
    carouselIndex(action, number = 0) {
      let valueIndex = this.indexViewFirst
      if (action === 'less') {
        valueIndex -= 1
      } else if (action === 'more') {
        valueIndex += 1
      } else {
        valueIndex = number
      }
      this.changeIndex(valueIndex)
    },
    checkTypeMime(mimeType) {
      let typeFile = 'another'
      switch (mimeType) {
      case 'application/pdf':
      case '.pdf':
        typeFile = 'iframePdf'
        break
      case 'image/jpeg':
      case 'image/png':
      case '.png':
      case '.jpeg':
      case '.jpg':
        typeFile = 'iframeImage'
        break
      }
      return typeFile
    },
    openModal() {
      this.setDataModal()
      
    },
    setDataModal() {
      this.elementSelected = this.arrayElements[this.indexViewFirst]
      if (!!this.elementSelected) {
        this.iframeUrl = decodeURIComponent(this.elementSelected.url)
        this.mimetypeAttachment = this.checkTypeMime(this.elementSelected.mimetype)
      }
    }
  }
}
</script>
<style lang="scss">
  .preview-iframe--container {
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .preview-iframe {
    width: 100%;
    height: 85vh;
    object-fit: contain;
    display: grid;
    justify-content: center;
    align-items: center;
  }
  .carousel--container--ticket {
    display: grid;
    grid-template-columns: 50px 1fr 50px;
    align-items: center;
    justify-items: center;
  }
  .carousel--arrow--ticket {
    scale: 4;
  }
  .carousel--dots--ticket {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 5px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .carousel--dots--container {
    display: grid;
    justify-items: center;
    align-items: center;
  }
</style>